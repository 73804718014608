<template>
<div class="reset-password">
	<Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
	<Loading v-if="loading"/>
<div class="form-wrap background">
    <form class="reset">
      <h1>Reset Password</h1>
			<p>Forgot your password? Enter your email to rest it</p>
      <div class="inputs">
        <div class="input">
          <label>Email</label>
          <input type="text" placeholder="Email" v-model="email" />
        </div>
      </div>
      <button @click.prevent="resetPassword">Reset</button>
    </form>
  </div> 
	</div>
</template>

<script>
import Modal from "../components/Modal";
import Loading from "../components/Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
export default {
	name: "Forgot Password",
	data() {
		return{
			email: "",
			modalActive: false,
			modalMessage: "",
			loading: null,
		}
	},
	components: {
		Modal,
		Loading,
	},
	methods: {
		resetPassword() {
			this.loading = true;
			firebase.auth().sendPasswordResetEmail(this.email).then(() => {
				this.modalMessage = "If your account exists, you will receive an email";
				this.loading = false;
				this.modalActive = true;
			}).catch(err => {
				this.modalMessage = err.message;
				this.loading = false;
				this.modalActive = true;
			});
		},
		closeModal() {
			this.modalActive = !this.modalActive;
			this.email = "";
		},
	},
};
</script>

<style lang="sass" scoped>

</style>